<template>
  <page-layout
    class="image"
    header-text="חודש התנסות חינם לנרשמים עכשיו!"
    sub-header-text="בסופו אפשר לבחור אם להמשיך למינוי בתשלום של ₪45 לחודש"
    :background-color="backgroundColor"
    :current-page="pageName"
    button-text="קדימה!"
    next-page="login"
  >
    <template v-slot:header>
      <div/>
    </template>
    <template v-slot:content>
      <span class="content-header ri-large-headline margin-top-ml margin-bottom-sm">
          נשמע טוב?
      </span>
      <span class="content-sub-header ri-large-body`">
        עוד רגע ויש לך תזרים משלך, נצטרך עוד כמה פרטים קטנים ואנחנו שם!
      </span>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'OneMonthFree',
  components: {
    PageLayout,
  },
  data() {
    return {
      backgroundColor: Colors.riseupYellow,
      pageName: 'one-month-free',
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/typography';

.image {
  background-image: url('~@/assets/wine-and-dine/other-products-intorduction/one-month-free.svg');
  background-repeat: no-repeat;
  background-size: contain;

  .content-sub-header {
    line-height: $ri-font-size-24;

  }
}
</style>
<style lang="scss">

.header-text {
  // to align header text and the background image
  max-width: 250px;
}
</style>
