<template>
<component @hook:mounted="trackCurrentPage" :is="pageComponent" />
</template>

<script>
import { mapActions } from 'vuex';
import Introduction from './from-other-products/Introduction';
import FullImage from './from-other-products/FullImage';
import OrganizedCategories from './from-other-products/OrganizedCategories';
import PersonalizeCategories from './from-other-products/PersonalizeCategories';
import ReducibleExpenses from './from-other-products/ReducibleExpenses';
import OneMonthFree from './from-other-products/OneMonthFree';
import FewMoreDetails from './from-other-products/FewMoreDetails';

export default {
  name: 'OtherProductsIntroduction',
  props: {
    currentPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      components: {
        Introduction,
        FullImage,
        OrganizedCategories,
        PersonalizeCategories,
        ReducibleExpenses,
        OneMonthFree,
        FewMoreDetails,
      },
    };
  },
  methods: {
    ...mapActions('introductionFromOtherProducts', ['trackView']),
    trackCurrentPage() {
      this.trackView(this.currentPage);
    },
  },
  computed: {
    pageComponent() {
      return this.components[this.currentPage] || Introduction;
    },
  },
};
</script>

<style scoped>

</style>
