<template>
  <page-layout
    header-text="נראה לך את התמונה המלאה"
    sub-header-text="כל ההכנסות וההוצאות שלך, במקום אחד, בצורה הכי נוחה למעקב"
    :img-src="require('@/assets/wine-and-dine/other-products-intorduction/full-image.png')"
    :background-color="backgroundColor"
    :current-page="pageName"
    next-page="organized-categories"
  />
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'FullImage',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupYellow,
      pageName: 'full-image',
    };
  },
};
</script>

<style scoped>

</style>
