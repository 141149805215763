<template>
  <page-layout
    header-text="עוד רגע ויש לך תזרים משלך, נצטרך עוד כמה פרטים קטנים ואנחנו שם!"
    :background-color="backgroundColor"
    :current-page="pageName"
    :next-page="loginPath"
    :with-header-margin="false"
    button-text="הבא"
  >
    <template v-slot:header>
      <div>
        <img class="logo" src="@/assets/logo-vertical.svg">
      </div>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import { mapGetters } from 'vuex';

export default {
  name: 'FewMoreDetails',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupYellow,
      pageName: 'few-more-details',
    };
  },
  computed: {
    ...mapGetters('introductionFromOtherProducts', ['loginPath']),
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';

.logo {
  width: 56px;
  margin-top: $ri-spacing-xxl;
}
</style>
