<template>
  <page-layout
    header-text="נזהה הוצאות ששווה לצמצם"
    sub-header-text="ונעודד אותך לבטל אותן"
    :img-src="require('@/assets/wine-and-dine/other-products-intorduction/reducible-expenses.png')"
    :background-color="backgroundColor"
    :current-page="pageName"
    variant="light"
    next-page="one-month-free"
  />
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'ReducibleExpenses',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupDarkRed,
      pageName: 'reducible-expenses',
    };
  },
};
</script>

<style scoped>

</style>
