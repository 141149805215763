<template>
  <page-layout
    header-text="נסדר את ההוצאות שלך"
    sub-header-text="כל הוצאה שלך תתעדכן אוטומטית ותסווג לקטגוריה המתאימה"
    :img-src="require('@/assets/wine-and-dine/other-products-intorduction/organized-categories.png')"
    :background-color="backgroundColor"
    :current-page="this.pageName"
    variant="light"
    next-page="personalized-categories"
  />
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'OrganizedCategories',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupBlue,
      pageName: 'organized-categories',
    };
  },
};
</script>

<style scoped>

</style>
