<template>
  <page-layout
    header-text="נתאים את הקטגוריות אליך"
    sub-header-text="אפשר לפתוח קטגוריות חדשות שמתאימות לך ולהגדיר יעד להוצאות"
    :img-src="require('@/assets/wine-and-dine/other-products-intorduction/personalize-categories.png')"
    :background-color="backgroundColor"
    :current-page="pageName"
    variant="light"
    next-page="reducible-expenses"
  />
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'PersonalizeCategories',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupBlue,
      pageName: 'personalized-categories',
    };
  },
};
</script>

<style scoped>

</style>
