<template>
  <div class="page" :style="{backgroundColor}">
    <div class="content">
      <div class="info">
        <slot name="header">
          <span class="skip ri-bold-body" @click="skip">לדלג</span>
        </slot>
        <span
          :class="['header-text',variant, 'ri-large-headline',{'margin-top-ml': withHeaderMargin}]"
        >
          {{ headerText }}
        </span>
        <span v-if="!!subHeaderText" :class="`sub-header-text ${variant} ri-large-body`">{{ subHeaderText }}</span>
        <slot name="content"/>
        <div class="stretch-over-padding">
          <img v-if="!!imgSrc" class="image" :src="imgSrc" />
        </div>
      </div>
      <div class="actions">
        <riseup-button class="button" :title="buttonText" :action="next" :variant="buttonVariant" size="slim"/>
        <slot name="footer"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PageLayout',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    subHeaderText: {
      type: String,
      required: false,
    },
    currentPage: {
      type: String,
      required: true,
    },
    nextPage: {
      type: String,
      required: true,
    },
    withHeaderMargin: {
      type: Boolean,
      required: false,
      default: true,
    },
    imgSrc: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'dark',
      validate() {
        return ['dark', 'light'].includes(this.variant);
      },
    },
    buttonText: {
      type: String,
      required: false,
      default: 'הבנתי, אפשר להמשיך',
    },
    backgroundColor: {
      type: String,
      default: Colors.riseupBlue,
    },
  },
  methods: {
    ...mapActions('introductionFromOtherProducts', ['trackNextClick', 'trackSkip']),
    next() {
      this.trackNextClick(this.currentPage, this.nextPage);
      this.$router.push(this.nextPage);
    },
    skip() {
      this.trackSkip(this.currentPage);
      this.$router.push(this.loginPath);
    },
  },
  computed: {
    ...mapGetters('introductionFromOtherProducts', ['loginPath']),
    buttonVariant() {
      return this.variant === 'dark' ? 'primary' : 'flat';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../scss/mixins';
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/typography';
@mixin text-color {
  &.light {
    color: $riseup-white;
  }

  &.dark {
    color: $riseup-black;
  }
}

.page {
  position: relative;
  width: 100%;
  height: 100%;

  .content {
    position: relative;
    height: 100%;
    padding: $ri-spacing-l;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      flex-shrink: 1;

      .skip {
        align-self: flex-end;
        margin-top: -($ri-spacing-sm);
        color: $riseup-white;
        cursor: pointer;
      }

      .header-text {
        @include text-color;
      }

      .sub-header-text {
        margin-top: $ri-spacing-xs;
        line-height: $ri-font-size-24;
        @include text-color;
      }

      .stretch-over-padding {
        margin: 0 (-$ri-spacing-l);
        flex-grow: 1;
        display: flex;

        .image {
          width: 100%;
          max-height: 414px;
        }
      }
    }

    .button {
      border-radius: 80px;
    }
  }
}

</style>
