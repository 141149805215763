<template>
  <page-layout
    header-text="עם תזרים RiseUp, אפשר לשנות את מערכת היחסים שלך עם כסף"
    :img-src="require('@/assets/wine-and-dine/other-products-intorduction/introduction.png')"
    :background-color="backgroundColor"
    :current-page="pageName"
    :with-header-margin="false"
    button-text="ספרו לי עוד"
    next-page="full-image"
  >
    <template v-slot:header>
      <div>
        <img class="logo" src="@/assets/logo-vertical.svg">
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer">
        <router-link class="ri-bold-body" to="login" @click="skipClicked">אפשר לדלג לשלב ההרשמה</router-link>
      </div>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PageLayout';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Introduction',
  components: { PageLayout },
  data() {
    return {
      backgroundColor: Colors.riseupYellow,
      pageName: 'introduction',
    };
  },
  methods: {
    ...mapActions('introductionFromOtherProducts', ['trackSkip']),
    ...mapGetters('introductionFromOtherProducts', ['loginPath']),
    skipClicked() {
      this.trackSkip(this.pageName);
      this.$router.push(this.loginPath);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

.logo {
  width: 56px;
  margin-bottom: $ri-spacing-sm;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: $ri-spacing-l;
  gap: $ri-spacing-xs;

  a, a:visited {
    color: $riseup-blue;
  }
}
</style>
